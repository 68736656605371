import './styles.css';
import React from 'react';

const DoList = ({ children }) => {
    return <div>
        <div className="my-8 do-list">
            <h4 className="text-sm font-bold text-gray-900 mb-3">Do:</h4>
            <ul>{children}</ul>
        </div>
    </div>
};

export default DoList;
