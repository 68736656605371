import React from 'react';

const DownloadButton = ({ link, children }) => {
    return <div>
        <a href={link} className="block py-4 px-5 bg-secondary my-4 rounded text-sm">
            <span>{children}</span>
            <svg className="inline-block w-4 align-top mt-1 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 12">
                <path d="M1.086 1.989H6.52V3.1H1.086v7.786h7.605V5.326h1.087V12H0V1.988h1.086zM7.602.046l3.42.041h.001l.977.01.01.977.002.001h-.002l.042 3.42-1.731-1.73-6.035 6.036-.99-.989 6.036-6.035-1.73-1.73z" fill="#0076C0" fillRule="evenodd" />
            </svg>
        </a>
    </div>
};

export default DownloadButton;
