import React from 'react';

const Image = ({ src, alt, title }) => {
    return <span>
        <img
            src={`${src}`}
            alt={alt}
            style={{
                maxWidth: 'none'
            }}
            onLoad={(ev) => {
                ev.target.width = ev.target.width / 2;
                ev.target.style.maxWidth = '100%';
            }}
            className={`${title ? `mt-8 mb-3` : `w-full my-3 border`} rounded`} />
    </span>
};

export default Image;
