import './styles.css';
import React from 'react';
import matchAll from 'string.prototype.matchall';
import { Carousel } from 'react-responsive-carousel';

const Slider = ({ children }) => {
    const regex = /!\[(?<alt>[^\]]*)\]\((?<filename>.*?)(?="|\))(?<optionalpart>".*")?\)/g;
    const images = [...matchAll(children, regex)];

    return <Carousel showThumbs={false} infiniteLoop={false} showStatus={false} swipeable={true}>
        {images.map(({ groups: image }, idx) => {
            return <img className="w-full" key={idx} src={`${image.filename}`} alt={image.alt} />
        })}
    </Carousel>
};

export default Slider;
