import './styles.css';
import React from 'react';

const Table = ({ children }) => {
    return <div>
        <div className="w-full my-12">
            {children}
        </div>
    </div>
};

export default Table;
