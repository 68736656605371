import React from 'react';
import { MDXProvider } from '@mdx-js/react';

import Heading1 from './blocks/heading-1';
import Heading2 from './blocks/heading-2';
import Heading3 from './blocks/heading-3';
import Paragraph from './blocks/paragraph';
import Image from './blocks/image';
import Slider from './blocks/slider/index';
import Icons from './blocks/icons';
import Icon from './blocks/icon/index';
import Table from './blocks/table/index';
import Thead from './blocks/table/thead';
import Tbody from './blocks/table/tbody';
import Tr from './blocks/table/tr';
import Th from './blocks/table/th';
import Td from './blocks/table/td';
import Callout from './blocks/callout';
import Colour from './blocks/colour/index';
import DownloadButton from './blocks/download-button';
import Spacer from './blocks/spacer';
import Recommendations from './blocks/recommendations/index';
import RecommendationLink from './blocks/recommendations/link';
import CodeSnippet from './blocks/code-snippet';
import DoList from './blocks/usage/do-list';
import DoNotList from './blocks/usage/donot-list';
import DoItem from './blocks/usage/do-item';

const components = {
    h1: Heading1,
    h2: Heading2,
    h3: Heading3,
    p: Paragraph,
    Slider,
    img: Image,
    Icons,
    Icon,
    table: Table,
    thead: Thead,
    tbody: Tbody,
    tr: Tr,
    th: Th,
    td: Td,
    Callout,
    Colour,
    DownloadButton,
    hr: Spacer,
    Recommendations,
    Link: RecommendationLink,
    pre: CodeSnippet,
    DoList,
    DoNotList,
    Item: DoItem
};

const wrapRootElement = ({ children }) => (
    <MDXProvider components={components}>{children}</MDXProvider>
);

export default wrapRootElement;
