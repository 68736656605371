import React from 'react';

const Tr = ({ children }) => {
    const isHead = children[0].props.originalType === 'th';

    return <>
        <div className={`custom-tr ${isHead ? `` : `border border-secondary mb-3`} p-3 lg:p-0 flex flex-row rounded`}>{children}</div>
    </>
};

export default Tr;
