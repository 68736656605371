import React from 'react';

const Paragraph = ({ children }) => {
    if (typeof children === 'string') {
        const lines = children.split('\n');

        return lines.length > 1 ? <span><p className="mb-3">
            {children.split('\n').map(line => {
                return <span>{line}<br /></span>
            })}
        </p></span> : <div><p>{children}</p></div>
    }
    else {
        return <p>{children}</p>
    }
};

export default Paragraph;
