import React, { useState } from 'react';
import { Link } from 'gatsby';

const LinkComponent = ({ title, link }) => {
    const [ hovered, setHover ] = useState(false);

    return <>
        <Link to={link} className="block w-1/2 p-2 hover:no-underline focus:no-underline" onFocus={() => { setHover(true); }} onBlur={() => { setHover(false); }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <div className={`${hovered ? `border-primary` : `border-secondary`} w-full rounded py-3 px-4 border relative text-sm`}>
                <span className="font-bold block text-blue-900 mb-1">{title}</span>
                
                <span className="text-primary">
                    <span>View</span>

                    <svg className="inline-block w-3 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 6">
                        <path d="M7.857 0l2.828 2.828-2.828 2.829-.001-2.201L0 3.457V2.2l7.856-.001V0z" fill="#0076C0" fillRule="evenodd" />
                    </svg>
                </span>
            </div>
        </Link>
    </>
};

export default LinkComponent;
