import './styles.css';
import React from 'react';
import matchAll from 'string.prototype.matchall';

const Colour = ({ name, hex, text, children }) => {
    const regex = /#{1,6}(?<title>.+)#{1,6}(?<content>.*)/g;
    const { title, content } = [...matchAll(children, regex)].pop().groups;

    const hexToRgb = function(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    };

    const rgb = hexToRgb(hex);

    return <div>
        <div className="custom-colour my-6 rounded bg-secondary p-4 flex flex-wrap">
            <div className="lg:w-1/12 mb-3 sm:mb-0">
                <div className={`w-10 h-10 lg:w-12 lg:h-12 mr-3 rounded flex items-center justify-center text-xl ${text ? `` : `text-white`}`} style={{
                    backgroundColor: hex,
                    color: text,
                    border: '1px solid #D3D7DE'
                }}>Aa</div>    
            </div>

            <div className="flex items-center font-bold lg:mb-0 w-10/12 lg:w-11/12 lg:pl-3">
                <h3 className="items-center text-gray-900">{title}</h3>
            </div>

            <div className="hidden lg:block w-1/12 h-1"></div>

            <div className="flex flex-col justify-center mt-3 lg:mt-0 w-10/12 lg:w-11/12 lg:pl-3 text-xs">
                <p className="text-xs leading-relaxed">{content}</p>

                <pre className="text-gray-600 text-xs">{name} <span className="ml-4">{hex}</span></pre>

                <pre className="mt-3 text-gray-600 text-xs">
                    <span className="mr-5">R: {rgb.r}</span>
                    <span className="mr-5">G: {rgb.g}</span>
                    <span className="mr-5">B: {rgb.b}</span>
                </pre>
            </div>
        </div>
    </div>
};

export default Colour;
