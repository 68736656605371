import React, { useState } from 'react';

const Icon = ({ type, link }) => {
    const [ hovered, setHover ] = useState(false);

    return <>
        <a href={link} className="block px-2 cursor-pointer w-full lg:w-1/3 hover:no-underline focus:no-underline" onFocus={() => { setHover(true); }} onBlur = {() => { setHover(false); }} onMouseEnter={() => { setHover(true); }} onMouseLeave={() => { setHover(false); }}>
            <div className={`${hovered ? `border-primary` : `border-secondary`} w-full my-2 border relative`}>
                {hovered ? <div className="absolute bg-primary w-8 h-8 top-0 right-0 m-3 flex justify-center rounded">
                    <svg className="w-4 m-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                        <path d="M2 7v5h10V7h2v7H0V7h2zm6-7v7h1.828L7 9.828 4.172 7H6V0h2z" fill="#FFF" fillRule="evenodd" />
                    </svg>
                </div> : ``}

                <div className="py-12">
                    <img src={`/images/Icons/${type}.png`} alt={type} className="mx-auto" style={{
                        maxWidth: '125px',
                        maxHeight: '125px'
                    }} />
                </div>

                <pre className={`text-sm px-2 py-2 border-t text-blue-900 ${hovered ? `border-primary` : `border-secondary`}`}>icon-{type}</pre>
            </div>
        </a>
    </>
};

export default Icon;
