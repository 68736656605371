import React from 'react';
import matchAll from 'string.prototype.matchall';

const Callout = ({ children }) => {
    const regex = /(#(?<title>[A-Za-z\s,.():<>]+)|#{2}(?<content>[A-Za-z\s,.():<>]+))/gm;
    const matches = [...matchAll(children, regex)];

    return <div>
        <div className="block p-5 bg-secondary my-8 rounded">
            {matches.map(({ groups: { title, content }}, idx) => {
                return (title) ? <h3 className="font-semibold text-sm text-gray-900" key={idx}>{title}</h3> : <p className="mt-3" key={idx}>{content}</p>
            })}
        </div>
    </div>
};

export default Callout;
